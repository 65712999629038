/**
 * A mandatory Connector Grid API Header
 *
 * @author Warren Shea
 */

/**
 * These values are mandatory and must be passed to the Connector Grid via the header
 *   x-fapi-interaction-id: All requests must implement traceability header x-fapi-interaction-id, to support tracking errors in a call tree and to identify where the problem is during runtime.  The value will abide by RFC 4122 UUID. This header is mandatory (MUST) as per TS1725-01 API Technical Standard.
 *   x-client-id: The Client Id is leveraged for the internal BMO API calls as calling channel identifier. For external BMO API calls, this header can be used as third-party provider application registration identifier. This header is mandatory (MUST) as per TS1725-01 API Technical Standard.
 *   x-request-id: Correlating request to an API to a response is important in an API design to track any kind of API behaviour or audit. All requests must x-request-id header to implement correlation, e.g. resend a request because of a dropped connection. This header is required (MUST) as per TS1725-01 API Technical Standard.
 *     The value can be changed as is currently a YYYY-MM-DD-hh:mm:ss as an identifier for the DynamoDB
 *   x-api-key: The unique id used to identify caller of the API. This header is required (MUST) as per TS1725-01 API Technical Standard.
 * 
 * @constant
 * @type (Record<string, string>)
 */

import getXRequestId from '../utils/getXRequestId.ts';

const connectorGridApiHeader: Record<string, string> = {
    'Content-Type': 'application/json',
    'x-fapi-interaction-id': 'universal-id-fapi-interaction-id',
    'x-client-id': 'universal-id-client-id',
    'x-request-id': getXRequestId(),
    'x-api-key': '9a0030fdbc3bc068351b5998091ac4df'
};

export default connectorGridApiHeader;