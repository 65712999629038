import browserCookieNames from '../constants/browserCookieNames';
import { ResponseBody } from 'globalTypes';

const getCookieValue = (cookieName: string) => {
    cookieName = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(';');

    for(let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        while (cookie.charAt(0) == ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(cookieName) == 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }
    return "";
};

const captureCookies = (userProfileData: ResponseBody) => {
    let allCookiesData: { [key: string]: string } = {};
    browserCookieNames.forEach((cookieName: string) => {
        if(getCookieValue(cookieName) !== '') {
            allCookiesData[`${cookieName}`] = getCookieValue(cookieName);
        }
    });
    return Object.assign(userProfileData.universalIds, allCookiesData);
};

export { captureCookies, getCookieValue };