import captureAndSendGeoData from './api/getAndSendGeoData.ts';
import { captureCookies, getCookieValue } from './collectors/collectBrowserCookies.ts';
// import defaultFormFieldMappings from './constants/formFieldMappings.ts';
import defaultUrlWhitelist from './constants/whitelistedPaths.ts';
import getAndSendFormData from './api/getAndSendFormData.ts';
import getMainDomainFromCurrentUrl from './collectors/collectDomain.ts';
import getUniversalIdCookie from './api/getUniversalIdCookie.ts';
import sendDataToConnectorGrid from './api/sendData.ts';
import userProfileData from './constants/payload.ts';
import { UidNamespace, ResponseBody, /* FormFieldMappings */ } from 'globalTypes';

window.universalId = window.universalId || {} as UidNamespace;
window.universalId.sendFormData = async (formDataObject: object): Promise<void> => {
    return new Promise(async (resolve) => {
        captureBrowserInfo(userProfileData);
        captureCookies(userProfileData);
        await captureAndSendGeoData(userProfileData);
        resolve (await getAndSendFormData(formDataObject, userProfileData));
    })
};

// Check if the current URL path is whitelisted
const isWhitelisted = (whiteListedPaths: string[]): boolean => {
    const currentURL = window.location.href;
    const isMatch = whiteListedPaths.some((path: string) => {
        return path === currentURL;
    });
    console.log(`UNIVERSAL ID: Is this page whitelisted? - ${isMatch}`)
    return isMatch;
};

// Detect the browser name
const detectBrowser = (): string => {
    const userAgent = window.navigator.userAgent;
    const isIE = userAgent.indexOf('Trident') > -1 || userAgent.indexOf('MSIE') > -1;
    const isEdge = userAgent.indexOf('Edg') > -1 || userAgent.indexOf('Edge') > -1;
    const isOpera = window.navigator.userAgent.indexOf('Opera') > -1 || window.navigator.userAgent.indexOf('OPR') > -1;
    const isFirefox = userAgent.indexOf('Firefox') > -1;
    const isChrome = userAgent.indexOf('Chrome') > -1 && !isEdge && !isOpera;
    const isSafari = userAgent.indexOf('Safari') > -1 && !isChrome && !isEdge && !isOpera;

    if (isIE) {
        return 'Internet Explorer';
    } else if (isEdge) {
        return 'Edge';
    } else if (isOpera) {
        return 'Opera';
    } else if (isFirefox) {
        return 'Firefox';
    } else if (isChrome) {
        return 'Chrome';
    } else if (isSafari) {
        return 'Safari';
    }
    return 'Unknown Browser';
};

// Capture and store browser information
const captureBrowserInfo = (userProfileData: ResponseBody): void => {
    const browserInfo = {
        userAgent: window.navigator.userAgent,
        browserType: detectBrowser(),
        language: document.documentElement.lang,
        refDomain: document.referrer,
        domain: getMainDomainFromCurrentUrl()
    }
    Object.assign(userProfileData.browserInfo, browserInfo)
};

// Create and store a new visitor session in local storage
// Session includes a unique ID and expiration time
const setUniversalIdVisitSession = (): void => {
    // const expirationTime = Date.now() + 4 * 60 * 60 * 1000;  // Session expires in 4 hours
    const expirationTime = Date.now() + 15 * 60 * 1000; 
    const visitorSession = {
        sessionId: `visitor_${Math.random().toString(36).substring(2, 9)}`,
        expirationTime: expirationTime
    };
    localStorage.setItem('universalIdVisitSession', JSON.stringify(visitorSession));
};

const captureDataElementsOnPageLoad = async (userProfileData: ResponseBody) => {
    captureBrowserInfo(userProfileData);
    captureCookies(userProfileData);
    await captureAndSendGeoData(userProfileData);
};

const checkUniversalIdVisitSession = async () => {
    const visitSessionValue = localStorage.getItem('universalIdVisitSession');
    /*
     * Steps to handle the visitor session:
     * 1. Verify if a visit session exists in local storage.
     * 2. If the session exists: 
     *    - Check if the current page is a part of a specific list of URLs (whitelisted).
     *    - Collect data such as browser details, cookies, and geolocation information.
     *    - Verify if a universal ID (uID) cookie is already set. 
     *    - If no uID cookie is found, request and store a new one.
     * 3. Determine whether the session has expired based on the session's expiration time.
     * 4. Depending on the results of these checks, send and recieve data from Connector Grid.
     */
    if(visitSessionValue) {
        const visitSessionValueJsonFormat = JSON.parse(visitSessionValue);
        const getCurrentTime = Date.now();
        const getExpirationTime = visitSessionValueJsonFormat.expirationTime;
        const uID = getCookieValue('uID');

        console.log("running whitelist");
        if(isWhitelisted(defaultUrlWhitelist)) {
            await captureDataElementsOnPageLoad(userProfileData);
            !uID ? getUniversalIdCookie(userProfileData) : sendDataToConnectorGrid(userProfileData);
        } else if(!uID) {
            await captureDataElementsOnPageLoad(userProfileData);
            getUniversalIdCookie(userProfileData);
        } else if(getCurrentTime >= getExpirationTime) {
            await captureDataElementsOnPageLoad(userProfileData);
            localStorage.removeItem('universalIdVisitSession');
            setUniversalIdVisitSession();
            !uID ? getUniversalIdCookie(userProfileData) : sendDataToConnectorGrid(userProfileData);
        }
        /*
         * Handling the case where no visit session exists: 
         * 1. Collect necessary data:
         *    - Browser-level information, cookies, and geolocation data.
         * 2. Check for the presence of a universal ID (uID) cookie: 
         *    - If no uID cookie is found, request and store a new one.
         * 3. Based on the conditions, send and recieve the data from Connector Grid.
         */
    } else {
        setUniversalIdVisitSession();
        captureBrowserInfo(userProfileData);
        captureCookies(userProfileData);
        await captureAndSendGeoData(userProfileData);
        const uID = getCookieValue('uID');
        !uID ? getUniversalIdCookie(userProfileData) : sendDataToConnectorGrid(userProfileData);
    }
};

const initializeUniversalIdFlow = (): void => {
    checkUniversalIdVisitSession();
};

initializeUniversalIdFlow();