// Extract the main domain from the current URL
// If the hostname has more than two parts (e.g. 'sub.example.com'), return the last two parts ('.example.com')
// Otherwise, return the hostname as is
const getMainDomainFromCurrentUrl = (): string => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    if(parts.length <= 2) {
        return hostname;
    }
    return `.${parts.slice(-2).join('.')}`;
};

export default getMainDomainFromCurrentUrl;