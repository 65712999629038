import apiConfig from './apiConfig.ts';
import { ResponseBody } from 'globalTypes';

const captureAndSendGeoData = async (userProfileData: ResponseBody) => {
    try {
        const response = await fetch(apiConfig.geolocation.getUserLocation);
        if (!response.ok) {
            throw new Error(`Network request to Geolocation API failed! Recieved status code: ${response.status}`);
        }
        const data = await response.json();

        // Preparing non personal identifiable Data to send to Connector Grid
        const browserInfo = {
            ipAddress: data.trueClientIP,
            geoLocation: `${data.stateOrProvince}, ${data.country}`
        };

        const nonPiiData = {
            country: data.country,
            state: data.stateOrProvince,
            city: data.city,
            psCode: data.zipOrPostalCode
        };

        Object.assign(userProfileData.browserInfo, browserInfo);
        Object.assign(userProfileData.nonPiiData, nonPiiData);
    }
    catch (error) {
        const err = error as Error;
        console.error(`Unable to get data from Geolocation API! Error: ${err.message || err}`);
    }
};

export default captureAndSendGeoData;