import captureAndSendGeoData from './api/getAndSendGeoData.ts';
import { captureCookies, getCookieValue } from './collectors/collectBrowserCookies.ts';
import defaultFormFieldMappings from './constants/formFieldMappings.ts';
import defaultUrlWhitelist from './constants/whitelistedPaths.ts';
import getAndSendFormData from './api/getAndSendFormDataFunctionalFlow.ts';
import getMainDomainFromCurrentUrl from './collectors/collectDomain.ts';
import getUniversalIdCookie from './api/getUniversalIdCookieFunctionalFlow.ts';
import sendDataToConnectorGrid from './api/sendData.ts';
import userProfileData from './constants/payload.ts';
import {  UidNamespace, ResponseBody, FormFieldMappings } from 'globalTypes';

window.universalId = window.universalId || {} as UidNamespace;
window.universalId.formFieldMappings = window.universalId.formFieldMappings || {};
window.universalId.urlWhiteList = window.universalId.urlWhiteList || [];

const mergeAllExplicitFields = (array1: string[], array2: string[]): string[] => {
    console.log('Universal ID: keys merged - ' + Array.from(new Set([...array1, ...array2])));
    return Array.from(new Set([...array1, ...array2]));
}

window.universalId.urlWhiteList = mergeAllExplicitFields(window.universalId.urlWhiteList, defaultUrlWhitelist);
window.universalId.addToUrlWhiteList = (newUrls: string[]): void => {
    window.universalId.urlWhiteList = mergeAllExplicitFields(window.universalId.urlWhiteList, newUrls);
}

const updateFormFieldMappings = (mappingsType: object):void => {
    for(const formFieldName in mappingsType) {
        if(Object.hasOwn(mappingsType, formFieldName)) {
            window.universalId.formFieldMappings[formFieldName] = mergeAllExplicitFields(
                window.universalId.formFieldMappings[formFieldName] || [],
                mappingsType[formFieldName as keyof object]
            )
        }
    }
}

updateFormFieldMappings(defaultFormFieldMappings);

window.universalId.addFormFieldMappings = (newFormFieldMappings: FormFieldMappings): void => {
    updateFormFieldMappings(newFormFieldMappings);
}

window.universalId.sendFormData = async (formDataObject: object): Promise<void> => {
    return new Promise(async (resolve) => {
        captureBrowserInfo(userProfileData);
        captureCookies(userProfileData);
        await captureAndSendGeoData(userProfileData);
        waitForOneTrustBanner('#onetrust-consent-sdk').then(async () => {
            const oneTrustActiveGroups = window.OnetrustActiveGroups;
            resolve (await getAndSendFormData(formDataObject, userProfileData, oneTrustActiveGroups));
            console.log("success");
        });
    })
};

//TODO: Need to add comment to describe this function
const waitForOneTrustBanner = (selector: string) => {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(() => {
            if (document.querySelector(selector)) {
                observer.disconnect();
                resolve(document.querySelector(selector));
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
};

// Check if the current URL path is whitelisted
const isWhitelisted = (whiteListedPaths: string[]): boolean => {
    let currentPath = window.location.pathname;
    const isMatch = whiteListedPaths.some(path => {
        return path === currentPath;
    });
    console.log(`UNIVERSAL ID: Is this page whitelisted? - ${isMatch}`)
    return isMatch;
};

// Detect the browser name
const detectBrowser = (): string => {
    const userAgent = window.navigator.userAgent;
    const isIE = userAgent.indexOf('Trident') > -1 || userAgent.indexOf('MSIE') > -1;
    const isEdge = userAgent.indexOf('Edg') > -1 || userAgent.indexOf('Edge') > -1;
    const isOpera = window.navigator.userAgent.indexOf('Opera') > -1 || window.navigator.userAgent.indexOf('OPR') > -1;
    const isFirefox = userAgent.indexOf('Firefox') > -1;
    const isChrome = userAgent.indexOf('Chrome') > -1 && !isEdge && !isOpera;
    const isSafari = userAgent.indexOf('Safari') > -1 && !isChrome && !isEdge && !isOpera;

    if (isIE) {
        return 'Internet Explorer';
    } else if (isEdge) {
        return 'Edge';
    } else if (isOpera) {
        return 'Opera';
    } else if (isFirefox) {
        return 'Firefox';
    } else if (isChrome) {
        return 'Chrome';
    } else if (isSafari) {
        return 'Safari';
    }
    return 'Unknown Browser';
};

// Capture and store browser information
const captureBrowserInfo = (userProfileData: ResponseBody): void => {
    const browserInfo = {
        userAgent: window.navigator.userAgent,
        browserType: detectBrowser(),
        language: document.documentElement.lang,
        refDomain: document.referrer,
        domain: getMainDomainFromCurrentUrl()
    }
    Object.assign(userProfileData.browserInfo, browserInfo)
};

// Create and store a new visitor session in local storage
// Session includes a unique ID and expiration time
const setUniversalIdVisitSession = (): void => {
    const expirationTime = Date.now() + 4 * 60 * 60 * 1000;  // Session expires in 4 hours
    const visitorSession = {
        sessionId: `visitor_${Math.random().toString(36).substring(2, 9)}`,
        expirationTime: expirationTime
    };
    localStorage.setItem('universalIdVisitSession', JSON.stringify(visitorSession));
};

const captureDataElementsOnPageLoad = async (userProfileData: ResponseBody) => {
    captureBrowserInfo(userProfileData);
    captureCookies(userProfileData);
    await captureAndSendGeoData(userProfileData);
};

const checkUniversalIdVisitSession = async (oneTrustActiveGroups: string) => {
    const visitSessionValue = localStorage.getItem('universalIdVisitSession');
    /*
     * Steps to handle the visitor session:
     * 1. Verify if a visit session exists in local storage.
     * 2. If the session exists: 
     *    - Check if the current page is a part of a specific list of URLs (whitelisted).
     *    - Collect data such as browser details, cookies, and geolocation information.
     *    - Verify if a universal ID (uID) cookie is already set. 
     *    - If no uID cookie is found, request and store a new one.
     * 3. Determine whether the session has expired based on the session's expiration time.
     * 4. Depending on the results of these checks, send and recieve data from Connector Grid.
     */
    if(visitSessionValue) {
        const visitSessionValueJsonFormat = JSON.parse(visitSessionValue);
        const getCurrentTime = Date.now();
        const getExpirationTime = visitSessionValueJsonFormat.expirationTime;
        const uID = getCookieValue('uID');
        const functionalCookieOptOut = !oneTrustActiveGroups.includes('C0003');

        if(isWhitelisted(window.universalId.urlWhiteList)) {
            await captureDataElementsOnPageLoad(userProfileData);
            if(functionalCookieOptOut) {
                getUniversalIdCookie(userProfileData, oneTrustActiveGroups);
            } else {
                !uID ? getUniversalIdCookie(userProfileData, oneTrustActiveGroups) : sendDataToConnectorGrid(userProfileData);
            }
        } else if(!uID && oneTrustActiveGroups.includes('C0003')) {
            await captureDataElementsOnPageLoad(userProfileData);
            getUniversalIdCookie(userProfileData, oneTrustActiveGroups);
        } else if(getCurrentTime >= getExpirationTime) {
            await captureDataElementsOnPageLoad(userProfileData);
            localStorage.removeItem('universalIdVisitSession');
            setUniversalIdVisitSession();
            !uID ? getUniversalIdCookie(userProfileData, oneTrustActiveGroups) : sendDataToConnectorGrid(userProfileData);
        }
        /*
         * Handling the case where no visit session exists: 
         * 1. Collect necessary data:
         *    - Browser-level information, cookies, and geolocation data.
         * 2. Check for the presence of a universal ID (uID) cookie: 
         *    - If no uID cookie is found, request and store a new one.
         * 3. Based on the conditions, send and recieve the data from Connector Grid.
         */
    } else {
        setUniversalIdVisitSession();
        await captureDataElementsOnPageLoad(userProfileData);
        const uID = getCookieValue('uID');
        const functionalCookieOptOut = !oneTrustActiveGroups.includes('C0003');
        if(functionalCookieOptOut) {
            getUniversalIdCookie(userProfileData, oneTrustActiveGroups)
            // TODO: If our team doesn't set cookie ourselves, add code here to set expiration date of cookie to the past
        } else {
            !uID ? getUniversalIdCookie(userProfileData, oneTrustActiveGroups) : sendDataToConnectorGrid(userProfileData);
        }
    }
};

waitForOneTrustBanner('#onetrust-consent-sdk').then(() => {
    console.log(`UNIVERSAL ID: OneTrustActiveGroups: ${window.OnetrustActiveGroups}`);
    const oneTrustActiveGroups = window.OnetrustActiveGroups;
    checkUniversalIdVisitSession(oneTrustActiveGroups);
    const pcSaveBtn = document.getElementsByClassName("save-preference-btn-handler onetrust-close-btn-handler")[0];
    if(pcSaveBtn) {
        pcSaveBtn.addEventListener('click', function() {
            setTimeout(()=> {
                console.log(`UNIVERSAL ID: Save Button Onclick OneTrustActiveGroups: ${oneTrustActiveGroups}`);
                if(!window.OnetrustActiveGroups.includes('C0003')) {
                    console.log('UNIVERSAL ID: You have opted out of functional cookies - after click event listener');
                    userProfileData.browserInfo.cookieExpiryInDays = 0;
                    getUniversalIdCookie(userProfileData, oneTrustActiveGroups);
                    // const cookieString = `uID=; Domain=${getMainDomainFromCurrentUrl}; expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/;`;
                    // document.cookie = cookieString;
                } else {
                    console.log('UNIVERSAL ID: You have NOT opted-out of functional cookies');
                }
            }, 1000) //quick timeout so that the consent receipt can be sent and the cookie can be updated
        });
    }
});