/**
 * A list of pathnames that, if matched with the page browser, forces a Connector Grid send.
 *
 * @author Jaskiran Chhokar
 */

/**
 * Steps for adding URLs:.
 * (1. LEGACY) Please add relative path names only
 * 1. URLS must be absolute paths -- created a new type to ensure added urls are absolute and not relative
 * 2. Please remove any query params from URL
 *    (e.g. https://www.bmo.com/path-name/?icid=12345 ==> https://www.bmo.com/path-name/)
 *    Only the URL Pathname will be compared
 * 
 * @constant
 * @type (AbsoluteURL[])
 */

export type AbsoluteURL = `${"http" | "https"}://${string}`;

const defaultUrlWhitelist: AbsoluteURL[] = [
    // '/en-ca/main/personal/bank-accounts/chequing-accounts/',
    // '/en-ca/main/personal/bank-accounts/chequing-accounts/performance/',
    // '/main/business/accounts/overview/',
    // '/principal/entreprise/comptes/apercu/',
    // '/main/personal/credit-cards/all-cards/',
    'https://www.bmo.com/principal/particuliers/cartes-de-credit/toutes-les-cartes/',
    'https://www.bmo.com/main/personal/mortgages/',
    'https://www.bmo.com/principal/particuliers/prets-hypothecaires/',
    'https://www.bmo.com/main/personal/mortgages/mortgage-rates/',
    'https://www.bmo.com/principal/particuliers/prets-hypothecaires/taux-hypothecaires/',
    'https://www.bmo.com/main/personal/bank-accounts/',
    'https://www.bmo.com/principal/particuliers/comptes-bancaires/',
    // '/en-us/high-yield-online-savings/',
    // '/en-us/high-yield-cd/',
    'https://www.bmo.com/home/personal/banking/rates/rates-at-bmo',
    'https://www.bmo.com/accueil/particuliers/services-bancaires/taux/taux-chez-bmo',
    'https://www.bmo.com/main/personal/investments/',
    'https://www.bmo.com/principal/particuliers/investissements/',
    // '/home/professional-cards',
    // '/home/club-rewards',
];

export default defaultUrlWhitelist;