import { ResponseBody } from 'globalTypes';

// Hardcoding this values in this object for testing purposes, will remove dummy data later
const userProfileData: ResponseBody = {
    originatorData: {
        'applicationCatalogueId': 'required-test-data',
        'country': 'required-test-data',
        'transitNumber': '',
        'locationId': '',
        'locationType': '',
        'channel': 'required-test-data',
        'employeeUserId': '',
        'employeeUserName': ''
    },
    universalIds: {
        'dar': '',
        's_fid': '',
        's_vi': '',
        's_ecid': '',
        'AMCV_121534B8527830F30A490D44%40AdobeOrg': '',
        'kndctr_121534B8527830F30A490D44_AdobeOrg_identity': '',
        'gclid': '',
        '_evga_52cd': '',
        '_evga_d0db': '',
        '_fbp': '',
        'uID': 'required-test-data',
        applicationId: ''
    },
    browserInfo: {
        'ipAddress': '',
        'userAgent': '',
        'geoLocation': '',
        'browserType': '',
        'language': '',
        'refDomain': '',
        'domain': '',
        'cookieExpiryInDays': 400
    },
    piiData: {
        'firstName': '',
        'lastName': '',
        'middleName': '',
        'fullName': '',
        'title': '',
        'dateOfBirth': '',
        'gender': '',
        'emailAddress': '',
        'primaryPhoneNum': '',
        'secondaryPhoneNum': '',
        'primaryAddress': '',
        'secondaryAddress': '',
        'additionalContactInfo': '',
        'city': '',
        'state': '',
        'country': '',
        'psCode': ''
    },
    nonPiiData: {
        'title': '',
        'dateOfBirth': '',
        'gender': '',
        'city': '',
        'state': '',
        'country': '',
        'psCode': ''
    }
};

export default userProfileData;