/**
 * A list of pathnames that, if matched with the page browser, forces a Connector Grid send.
 *
 * @author Jaskiran Chhokar
 */

/**
 * Steps for adding URLs:.
 * 1. Please add relative path names only
 * 2. Please remove any query params from URL
 *    (e.g. https://www.bmo.com/path-name/?icid=12345 ==> https://www.bmo.com/path-name/)
 *    Only the URL Pathname will be compared
 * 
 * @constant
 * @type (string[])
 */
const defaultUrlWhitelist: string[] = [
    // '/en-ca/main/personal/bank-accounts/chequing-accounts/',
    // '/en-ca/main/personal/bank-accounts/chequing-accounts/performance/',
    // '/main/business/accounts/overview/',
    // '/principal/entreprise/comptes/apercu/',
    // '/main/personal/credit-cards/all-cards/',
    '/principal/particuliers/cartes-de-credit/toutes-les-cartes/',
    '/main/personal/mortgages/',
    '/principal/particuliers/prets-hypothecaires/',
    '/main/personal/mortgages/mortgage-rates/',
    '/principal/particuliers/prets-hypothecaires/taux-hypothecaires/',
    '/main/personal/bank-accounts/',
    '/principal/particuliers/comptes-bancaires/',
    '/en-us/high-yield-online-savings/',
    '/en-us/high-yield-cd/',
    '/home/personal/banking/rates/rates-at-bmo/',
    '/accueil/particuliers/services-bancaires/taux/taux-chez-bmo',
    '/main/personal/investments/',
    '/principal/particuliers/investissements/',
    '/home/professional-cards',
    '/home/club-rewards'
];

export default defaultUrlWhitelist;