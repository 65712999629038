import apiConfig from '../api/apiConfig.ts';
// import getMainDomainFromCurrentUrl from '../collectors/collectDomain.ts';
import { ResponseBody } from 'globalTypes';
import connectorGridApiHeader from '../constants/connectorGridApiHeader.ts';

// Set the uID cookie in the browser
// const setUniversalIdCookie = (cookieName: string, cookieValue?: string): string => {
//     const domain = getMainDomainFromCurrentUrl();
//     const cookieString = `${cookieName} = ${(cookieValue || '')}; Domain=${domain}; Max-Age=34560000; Path=/; SameSite=None; Secure`;
//     return document.cookie = cookieString;
// };

/* 
 * Retrieve uID cookie and send data to Connector Grid:
 * If a uID exists, include it in the data.
 * Otherwise, send data without uID.
 */
const getUniversalIdCookie = async (userProfileData: ResponseBody, oneTrustActiveGroups: string) =>  {
    const userProfileDataWithoutUID = JSON.parse(JSON.stringify(userProfileData));
    delete userProfileDataWithoutUID.universalIds.uID;

    if(!oneTrustActiveGroups.includes('C0003')) {
        console.log('UNIVERSAL ID: You have opted out of functional cookies! - on page load');
        userProfileDataWithoutUID.browserInfo.cookieExpiryInDays = 0;
    }

    try {
        const response = await fetch(apiConfig.connectorGrid.getUIDCookie, {
            method: 'POST',
            credentials: 'include',
            headers: connectorGridApiHeader,
            body: JSON.stringify(userProfileDataWithoutUID)
        });
        if(!response.ok) {
            throw new Error(`Network request to Connector Grid failed! Recieved status code: ${response.status}`);
        }
        return await response.json();
        // const data = await response.json();
        // if(!oneTrustActiveGroups.includes('C0003')) {
        //     console.log('UNIVERSAL ID: You have opted out of functional cookies! - on page load');
        //     const cookieString = `uID=; Domain=.bmo.com; expires=Thu, 01 Jan 1970 00:00:00 UTC; Secure; Secure; Path=/;`;
        //     document.cookie = cookieString;
        // } else {
        //     console.log('UNIVERSAL ID: You have NOT opted out of functional cookies! - on page load');
        // }
    } catch(error) {
        const err = error as Error;
        console.error(`Error in getUniversalIdCookie: ${err.message || err}`);
    }
};

export default getUniversalIdCookie;