/**
 * Configuration object for API endpoints
 *
 * @author Jaskiran Chhokar
 * 
 * @constant
 * @type (object)
 */
const apiConfig = {
    /**
     * Configuration object the Connector Grid API
     * 
     * These endpoints are used for interacting with the Connector Grid
     */
    connectorGrid: {
        /**
         * URL endpoint for sending data elements (without uID) and retrieving UID Cookies (uID and hpo_uID)
         * @type {string}
         */ 
        getUIDCookie: import.meta.env.VITE_GET_UID_COOKIE,
        /**
         * URL endpoint for sending data elements (with uID)
         * @type {string}
         */ 
        sendData: import.meta.env.VITE_SEND_DATA_TO_CONNECTOR_GRID
    },
    /**
     * Configuration object the Geolocation API
     * 
     * URL endpoint for retreving various non-Pii user information (country, city, zip/postal code, etc.)
     * @type {string}
     */
    geolocation: {
        getUserLocation: import.meta.env.VITE_GEOLOCATION_URL
    }
};

export default apiConfig;