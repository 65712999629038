/**
 * A list of cookie names that we need to send to Connector Grid.
 *
 * @author Jaskiran Chhokar
 */

/**
 * These cookies are grouped by their purpose/the service that uses them.
 * To add an additional cookie to this list, please hardcode the exact cookie name down below:
 * 
 * @constant
 * @type (string[])
 */
const cookieNames: string[] = [
    'uID',    // Internal bmo.com cookie
    'dar',    // Internal bmo.com cookie - dar id gets generated after signing into OLB
    's_fid',    // Adobe analytics cookie - fallback visitor id
    's_vi',    // Adobe analytics cookie - visitor id
    's_ecid',    // Adobe analytics cookie - adobe experience cloud id
    'AMCV_121534B8527830F30A490D44%40AdobeOrg',    // Adobe analytics cookie
    'kndctr_121534B8527830F30A490D44_AdobeOrg_identity',    // Adobe analytics cookie
    'gclid',    // Google click identifier
    '_evga_52cd',    // Salesforce personalization cookie
    '_evga_d0db',    // Salesforce personalization cookie
    '_fbp'    // Facebook cookie
];

export default cookieNames;