/**
 * This file maps various form field names from user-submitted data to standard names.
 *
 * @author Jaskiran Chhokar
 */
import { DefaultFormFieldMappings } from 'globalTypes';

/**
 * Form fields may have different names (e.g. 'first-name', 'FIRSTNAME', etc.)
 * This object ensures that all field names will get mapped to a standard name (e.g. 'firstName', 'lastName', etc),
 * ensuring consistency before the data is sent to Connector Grid.
 *
 * Keys represent the standard name & the arrays list possible name variations found in forms/form data.
 * 
 * @constant
 * @type (DefaultFormFieldMappings)
 */
const defaultFormFieldMappings: DefaultFormFieldMappings  = {
    firstName: [
        'firstname',
        'first-name',
        'firstName',
        'name',
        'contactName',
        'first_name',
        'FirstName',
        'FIRSTNAME',
        'VendorFirstName',
        'clientFirstName',
        'firstName1',
        'username',
        'nameInput',
        'personal-info-component-first-name-input-element',
        'name-block-first-name-element',
    ],
    lastName: [
        'lastname',
        'last-name',
        'lastName',
        'last_name',
        'LastName',
        'LASTNAME',
        'VendorLastName',
        'clientLastName',
        'lastName1',
        'personal-info-component-last-name-input-element',
        'name-block-last-name-element'
    ],
    middleName: [
        'middleName',
        'name-block-middle-name-element'
    ],
    fullName: [
        'contactName',
        'name',
        'fullname',
        'personName',
        'firstLastName',
        'placeholder-id'
    ],
    emailAddress: [
        'emailAddress',
        'email',
        'emailAddr',
        'EmailAddress',
        'fromAddress',
        'emailExistingClient',
        'EMAIL',
        'emailaddress',
        'clientEmailAddr',
        'phone-page-component-email-input-form-element',
        'email-input-field-element'
    ],
    primaryPhoneNum: [
        'mobile',
        'phoneNum',
        'phoneNumber',
        'PhoneNumber',
        // 'PHONENUMBER',
        'homephone',
        'phone',
        'clientPhoneNum',
        'phone-page-component-phone-input-element',
        'phone-block-input-field-element'
    ],
    secondaryPhoneNum: [
        'secondaryPhoneNum',
        'placeholder-id'
    ],
    additionalContactInfo: [
        // 'preferredContactInfo',
        'placeholder-id'
    ],
    dateOfBirth: [
        'dateOfBirth',
        'date-of-birth-date-picker-element',
        'dob-input-field-element'
    ],
    addressLineOne: [
        'address',
        'address1',
        'streetAddress',
        'address-page-component-streetNumber-input-element',
        'address-page-component-streetName-input-element',
        'address-input-address-input-field-element',
        'address-input-field-street-name-field-element'
    ],
    addressLineTwo: [
        'address2',
        'address-page-component-streetName-input-element'
    ],
    city: [
        // 'city',
        'CITY',
        'clientCity',
        'address-page-component-city-input-element',
        'address-input-field-city-input-field-element'
    ],
    state: [
        'visaCategory',
        'state',
        'province',
        'PROVINCEFIELD',
        'clientProvince',
        'address-page-component-province-input-element',
        'address-input-field-province-input-field-element'
    ],
    psCode: [
        // 'zip',
        'zipCode',
        // 'postalCode',
        // 'POSTALCODE',
        'zipcode',
        'address-page-component-postalCode-input-element',
        'address-input-field-postal-code-input-field-element'
    ],
    title: [
        'Title',
        'placeholder-id'
    ],
    gender: [
        'gender',
        'placeholder-id'
    ]
};

export default defaultFormFieldMappings;