/**
 * Gerates a timestamp string in the format 'YYYY-MM-DD-hh:mm:ss'.
 * 
 * The function uses the current date and time to construct the string.
 * 
 * @returns {string} A string representing the current date and time in the format 'YYYY-MM-DD-hh:mm:ss'
 * 
 * @example
 * const timestamp = getXRequestId();
 * console.log(timestamp); // Example: "2024-12-25-12:00:00"
 *
 * @author Warren Shea
 */

const getXRequestId = (): string => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}-${hours}:${minutes}:${seconds}`;
}

export default getXRequestId;